<template>
  <div class="bt-cif pa-5">
    <Header
      title="Pencairan Pembiayaan"
      titleIcon="mdi-note-plus-outline"
      class="mb-4"
    />
    <v-container class="pa-0">
      <div class="">
        <div class="w-full white elevation-3 rounded-lg pa-3 mb-3">
          <v-form ref="form" v-model="isFormValid" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-select
                  color="black"
                  autocomplete="off"
                  outlined
                  :items="list.hari_transaksi"
                  v-model="form.hari_transaksi"
                  label="Hari Transaksi"
                  required
                  :rules="[(v) => !!v || 'Hari Transaksi is required']"
                />
                <v-select
                  color="black"
                  autocomplete="off"
                  outlined
                  :items="list.cm"
                  v-model="form.cm_code"
                  label="Rembug"
                  required
                  :rules="[(v) => !!v || 'Rembug is required']"
                />
                <v-text-field
                  type="date"
                  color="black"
                  autocomplete="off"
                  outlined
                  v-model="form.tanggal_akad"
                  label="Tanggal Akad"
                  required
                  @change="getMemberDroping()"
                  :rules="[(v) => !!v || 'Tanggal Akad is required']"
                />
                <v-select
                  color="black"
                  autocomplete="off"
                  outlined
                  :items="list.account_financing_no"
                  v-model="form.account_financing_no"
                  label="No. Rekening"
                  required
                  :rules="[(v) => !!v || 'No. Rekening is required']"
                />
                <v-file-input
                  color="black"
                  outlined
                  label="Kwitansi / Nota"
                  v-model="form.file_receipt"
                  accept="image/*"
                  chips
                  prepend-icon="mdi-camera"
                  placeholder="Pilih File"
                />
                <div class="mb-2">
                  <label for="ttd_tpl" class="mb-2">TTD TPL</label>
                  <VueSignaturePad
                    id="ttd_tpl"
                    width="100%"
                    height="150px"
                    ref="ttd_tpl"
                    :options="signatureOptions"
                    style="border: 1px solid #ccc"
                  />
                  <v-btn text small color="red" @click="clearTtd('ttd_tpl')">
                    <v-icon>mdi-broom</v-icon> Clear
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>

      <v-row>
        <v-col cols="6" class="pb-0">
          <router-link to="/pembiayaan/dashboard">
            <v-btn block class="purple lighten-1 white--text">Kembali </v-btn>
          </router-link>
        </v-col>
        <v-col cols="6" class="pb-0">
          <v-btn
            block
            class="purple lighten-1 white--text"
            @click="doSave()"
            :disabled="!isFormValid"
          >
            Simpan
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar v-model="alert.show" :timeout="5000">
      {{ alert.msg }}
    </v-snackbar>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import services from "@/services";
import helper from "@/utils/helper";
import Toast from "@/components/Toast";
import Header from "@/components/Header";

export default {
  name: "PencairanPembiayaan",
  components: {
    Toast,
    Header,
  },
  data() {
    return {
      signatureOptions: {
        penColor: "#c0f",
      },
      list: {
        hari_transaksi: [
          {
            value: "1",
            text: "Senin",
          },
          {
            value: "2",
            text: "Selasa",
          },
          {
            value: "3",
            text: "Rabu",
          },
          {
            value: "4",
            text: "Kamis",
          },
        ],
        cm: [],
        account_financing_no: []
      },
      form: {
        hari_transaksi: null,
        cm_code: null,
        tanggal_akad: new Date().toISOString().substr(0, 10),
        account_financing_no: null,
        file_receipt: null,
        receipt: null,
        ttd_tpl: null
      },
      isFormValid: true,
      alert: {
        show: false,
        msg: "",
      },
      overlay: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  watch: {
    "form.hari_transaksi": {
      handler: function (newValue) {
        if (newValue) {
          this.getInfoRembug();
        }
      },
      immediate: true,
    },
    "form.cm_code": {
      handler: function (newValue) {
        if (newValue) {
          this.getMemberDroping();
        }
      },
      immediate: true,
    },
    overlay: {
      handler: function (newValue) {
        if (newValue) {
          this.overlay = true;
        } else {
          this.overlay = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...helper,
    clearTtd(field) {
      this.form[field] = null;
      this.$refs[field].undoSignature();
    },
    async doSave() {
      if (!this.$refs.form.validate()) return;

      const signatures = [
        this.$refs.ttd_tpl,
      ];

      signatures.forEach((signature, index) => {
        const { isEmpty: isEmptySignature, data: signatureData } =
          signature.saveSignature();
        this.form[
          `ttd_${["tpl"][index]}`
        ] = isEmptySignature ? null : signatureData;
      });

      this.form.receipt = this.form.file_receipt
        ? await this.fileToBase64(this.form.file_receipt)
        : null;

      this.overlay = true;
      let payload = new FormData();
      payload.append("account_financing_no", this.form.account_financing_no);
      payload.append("receipt", this.form.receipt);
      payload.append("ttd_tpl", this.form.ttd_tpl);
      payload.append("fa_code", this.user.fa_code);
      try {
        const req = await services.prosesPencairan(
          payload,
          this.user.token
        );
        if (req.data.status === true) {
          this.alert = {
            show: true,
            msg: req.data.msg,
          };

          setTimeout(() => {
            this.$router.push(`/pembiayaan/dashboard`);
          }, 1500);
        } else {
          this.alert = {
            show: true,
            msg: req.data.msg,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
      this.overlay = false;
    },
    async getInfoRembug() {
      this.overlay = true;
      let payload = new FormData();
      payload.append("fa_code", this.user.fa_code);
      payload.append("hari_transaksi", this.form.hari_transaksi);
      try {
        const req = await services.infoRembug(payload, this.user.token);
        if (req.status === 200) {
          if (Array.isArray(req.data.data)) {
            const cm =
              req.data.data.map((item) => {
                return {
                  value: item.cm_code,
                  text: item.cm_name,
                };
              }) || [];
            this.list.cm = cm;
          } else {
            this.alert = {
              show: true,
              msg: "Rembug tidak ditemukan",
            };
          }
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
      this.overlay = false;
    },

    async getMemberDroping() {
      this.overlay = true;
      let payload = new FormData();
      payload.append("cm_code", this.form.cm_code);
      payload.append("transaction_date", this.form.tanggal_akad);
      try {
        const req = await services.memberDroping(payload, this.user.token);
        if (req.data.status === true) {
          const account_financing_no =
            req.data.data.map((item) => {
              return {
                value: item.account_financing_no,
                text: item.nama,
              };
            }) || [];

          this.list.account_financing_no = account_financing_no;
        } else {
          this.alert = {
            show: true,
            msg: req.data.msg,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
      this.overlay = false;
    }
  },
  mounted() {
    /* */
  },
};
</script>
